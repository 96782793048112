.carousel-caption {
    display: block !important;
    visibility: visible !important;
    
    
    /* z-index: 9999 !important; */ /* Bring captions to the front */
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    padding: 10px;
    border-radius: 8px;
   /*  z-index: 9999; */ /* Ensure it's above other elements */
    width: 90%;
    visibility: visible !important;

}

/* Responsive styling */
@media (max-width: 768px) {
    .carousel-caption h3 {
        font-size: 16px;
    }
    .carousel-caption p {
        font-size: 12px;
    }
    .carousel-caption {
        bottom: 5%;
        width: 95%;
    }
}

.carousel-item img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
}

.carousel-item {
    position: relative;
    overflow: visible; /* Ensure captions are not clipped */
}
